/*@import "variables";*/
@import "bootstrap";

$color-1: #e2e5e7;
$color-2: #f9f9f9;
$color-3: #de0935;
$color-4: #212529;
$color-5: #1f3244;
$color-6:#9e6912;
$color-7:#e6022f;
$color-8:#4e4e4e;
$color-9:#000000;

:focus {
  outline-style: none;
  outline-width: 0px !important;
  outline-color: none !important;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.container {
  position: relative;
  display: block;
  width: 1200px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.main__list {
  position: relative;
}

.main__list {
  position: relative;
}
.w-list-unstyled {
  padding-left: 0;
  list-style: none;
}
ul {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 40px;
}

.formula__wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1200px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

div {
  display: block;
}
.formula {
  padding-top: 45px;
  text-align: center;
}
.section__subtitle {
  position: relative;
  top: -20px;
  z-index: 10;
  font-size: 40px;
  line-height: 1em;
}
p {
  margin-bottom: 0px;
}
p {
  margin-top: 0;
  margin-bottom: 10px;
}

h1 {
    text-shadow: 0 1px 81px rgba(36,58,69,.8);
}
.element-animation.element-show {
  opacity: 1;
  transition: all 1s;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}
.element-animation {
  opacity: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
.header {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 100;
  margin-bottom: -78px;
  padding: 0px 20px;
  background-color: hsla(0, 2%, 82%, .65);
}
.logo{
  width: 100px;
}
.navbar-brand{
  img{
    width: 70px;
  }
}
.header.fixed {
    position: fixed;
  top: 0;
    background-color: $color-2;
}
.nav__link {
  padding: 0px 7px;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  font-family: MullerBold, sans-serif;
  font-size: 20px;
  font-weight: 700;
}
.w-nav-link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
a {
  background-color: transparent;
}
.how__list-item {
  min-height: 26px;
  margin-bottom: 10px;
  padding-left: 30px;
  background-image: url(../images/bullit.svg);
  background-position: 0px 3px;
  background-repeat: no-repeat;
}

.format__box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 75px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.format__description {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 83%;
  min-height: 115px;
  padding: 30px 15px 20px 145px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: $color-2;
  text-align: left;
}
.format__title {
  position: absolute;
  left: -60px;
  top: -50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.format__number {
  width: 163px;
  margin-right: 15px;
  font-family: MullerHeavy, sans-serif;
  color: #41bd0b;
  font-size: 118px;
  line-height: 1em;
  font-weight: 900;
  text-shadow: 2px 2px 2px #b3a991;
}
.format__subtitle {
  position: relative;
  z-index: 10;
  margin-bottom: 15px;
  font-size: 40px;
  line-height: 1em;
}
.program__dop li {
    position: relative;
}
.program__dop ol {
  counter-reset:li;
}
.program__dop ol li:before {
    content:counter(li);
  counter-increment:li;
  position: absolute;
  top: 0px;
  left: -30px;    
  color: #2AAA05;
  font-size: 30px;
  font-weight: bold;
}
.sticky__img {
  position: -webkit-sticky !important;
  position: sticky !important;
  width: 100px;
}
.tarif__list .plus:before {
    content: '+';
    position: absolute;
    top: 50%;
    left: -22px;
    font-size: 34px;
    font-weight: bold;
    color: #ed495e;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
@media (min-width: 1680px) {
    .promo__date {
      color: #fff;
  }
}
@media (min-width: 991px) and (max-width: 1240px) {
    .formula__box {
      font-size: 18px;
    line-height: 20px;
  }
  .coach__description {
      width: 54%;
  }
}
@media (min-width: 991px) and (max-width: 1180px) {
    .formula__sign {
      width: 32px;
    right: -30px;    
  }
  .cash-img {
      width: 50vw;
  }
  .coins-img {
      width: 22vw;
  }
  .format__title {
      top: -39px;
      left: -13px;
  }
  .format__number {
      width: 126px;
      font-size: 90px;
    margin-right: 12px;
  }
  .format__subtitle {
    margin-bottom: 10px;
    font-size: 32px;
    }
  .program__title-bg {
      height: 135px;
  }
}
@media (max-width: 767px) {
  .program__dop ol li:before {
    left: -22px;    
    font-size: 24px;
  }
}
/*---------------------------------------*/
body {
    font-family: MullerRegular, sans-serif;
    color: #353631;
    font-size: 24px;
    line-height: 26px;
    margin: 0px !important;
  }
  
  h1 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-family: MullerHeavy, sans-serif;
    font-size: 124px;
    line-height: 1em;
  }
  
  h2 {
    margin-top: 0px;
    margin-bottom: 35px;
    font-family: MullerBold, sans-serif;
    color: #48453d;
    font-size: 38px;
    line-height: 42px;
    text-align: center;
  }
  
  p {
    margin-bottom: 0px;
  }
  
  ul {
    margin-top: -20px;
    margin-bottom: 0px;
    padding-left: 40px;
  }
  
  .header {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    z-index: 100;
    margin-bottom: -78px;
    padding: 0px 20px;
    background-color: hsla(0, 2%, 82%, .65);
  }
  
  .navbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1200px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
  }
  .border-round-box{
    padding: 20px;
    border:solid 3px #ffffff;
    border-radius: 10px;
  }
  
  .nav__logo {
    width: 138px;
    margin-top: -28px;
    padding-left: 0px;
    float: none;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  
  .nav__link {
    padding: 0px 7px;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    font-family: MullerBold, sans-serif;
    font-size: 20px;
    font-weight: 700;
  }
  
  .nav__link:hover {
    color: #f42034;
  }
  
  .nav__link.w--current {
    color: #f42034;
  }
  
  .btn{
    display: inline-block;
    min-width: 350px;
    padding: 25px 35px;

    background-image: url(../../images/pay.webp);
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; 

    font-family: MullerRegular, sans-serif;
    color: #fff !important;
    font-size:  28px;
    /*line-height: 1em;*/
    font-weight: 400;
    text-align: center;
    text-decoration: none;
  }
  .tinkoffPayRowBTN,#promokod_btn_1,#promokod_btn_2,#promokod_check,.request_btn{
    display: inline-block;
    /*min-width: 350px;*/
    /*padding: 20px 35px;*/

    background-image: url(../../images/pay.webp);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; 

    font-family: MullerRegular, sans-serif;
    color: #fff !important;
    font-size:  20px;
    /*line-height: 1em;*/
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    font-weight: 400 !important;
  }
  #promokod_btn_1,#promokod_btn_2,#promokod_check ,.request_btn {
    filter: hue-rotate(191deg) grayscale(0.1);
  }
  #promokod_btn_1,#promokod_btn_2{
    margin-bottom: 30px;
  }
  
  .btn.nav__btn {
    min-width: auto;
    margin-bottom: 4px;
    padding: 8px 12px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    font-size: 18px;
  }
  
  .btn.promo__btn {
    display: inline-block;
    min-width: auto;
    margin-top: 40px;
    margin-bottom: 50px;
    padding: 15px 20px;
  }
  
  .btn.reason__btn {
    position: absolute;
    left: 0;
    right: 0;
    margin-bottom:50px;
    margin:auto;
    z-index: 5;
    max-width: 350px;

  }
  
  .btn.slider__btn {
    margin-bottom: 0px;
  }
  
  .btn.how__btn {
    position: relative;
    bottom: -25px;
    display: inline-block;
    margin-bottom: 0px;
  }
  
  .review {
    padding: 80px 0px 80px 0px;
    background-color: $color-5;
  }
  
  .review.bottom {
    padding-top: 60px;
    padding-bottom: 80px;
    background-image: url("../../images/delimiter.webp");
    background-position: 50% 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .review__content {
    width: 80%;
    margin-top: 25px;
    padding-right: 30px;
    padding-bottom: 40px;
    padding-left: 30px;
    float: right;
    background-color: $color-1;
  }
  
  .review__content.small {
    font-size: 20px;
    line-height: 24px;
  }
  
  .formula__box {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 23%;
    padding: 30px 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: MullerBold, sans-serif;
    font-size: 20px;
    line-height: 24px;
  }
  
  .formula__box.left {
    background-image: url("../../images/formula-bg_left.webp");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .formula__box.center {
    background-image: url("../../images/formula-bg_center.webp");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .formula__box.right {
    background-image: url("../../images/formula-bg_right.webp");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .reason {
    position: relative;
    /*overflow: hidden;*/
    padding: 150px 20px 150px;
    background-image: url("../../images/delimiter.webp");
    background-position: 50% 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .review__box {
    width: 50%;
    margin-bottom: 65px;
  }
  
  .review__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .promo__sutitle {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 26px;
  }
  
  .arrow__line {
    position: absolute;
    left: 49%;
    top: 0px;
    width: 3px;
    height: 80%;
    background-color: #f42034;
  }
  
  .section__subtitle {
    position: relative;
    top: -50px;
    z-index: 10;
    font-size: 40px;
    line-height: 1em;
  }
  
  
  .steps__img {
    max-height: 92px;
    margin-bottom: 15px;
  }
  
  .formula__bg {
    position: relative;
    margin-top: 55px;
    padding-right: 20px;
    padding-bottom: 65px;
    padding-left: 20px;
    background-color: $color-2;
  }
  
  .review__video {
    left: -50px;
    top: -20px;
  }
  
  .how {
   /* overflow: hidden;
    padding-top: 80px;
    padding-bottom: 50px;
    padding-left: 20px;
    position: relative;
    overflow: hidden;
    background-image: url(../../images/delimiter.webp);
    background-position: 50% 100%;
    background-size: contain;
    background-repeat: no-repeat;*/
  }
  
  .steps {
    padding: 60px 20px 80px;
    background-image: url("../../images/delimiter.webp");
    background-position: 50% 100%;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
  }
  .steps__img__box{
    height: 100px;
    filter: sepia(100) saturate(50) hue-rotate(346deg);
  }
  .steps__img__box svg{
     fill:#2AAA05;
  }
  .arrow__left {
    position: absolute;
    left: 45%;
    bottom: -1px;
    width: 14px;
    height: 3px;
    background-color: #f42034;
    -webkit-transform: rotate(45deg) translate(-50%, 0px);
    -ms-transform: rotate(45deg) translate(-50%, 0px);
    transform: rotate(45deg) translate(-50%, 0px);
  }
  
  .formula {
    padding-top: 45px;
    text-align: center;
  }
  
  .arrow__right {
    position: absolute;
    left: 44.5%;
    bottom: -1px;
    width: 14px;
    height: 3px;
    background-color: #f42034;
    -webkit-transform: rotate(-45deg) translate(50%, 0px);
    -ms-transform: rotate(-45deg) translate(50%, 0px);
    transform: rotate(-45deg) translate(50%, 0px);
  }
  
  .container {
    position: relative;
    display: block;
    width: 1200px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .container.how__container {
    overflow: hidden;
    width: 1240px;
    padding-bottom: 25px;
  }
  
  .formula__wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1200px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .arrow {
    position: absolute;
    left: 50%;
    right: 0px;
    bottom: -45px;
    /*overflow: hidden;*/
    width: 130px;
    height: 110px;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
    text-align: center;
  }
  
  .arrow.reason__arrow {
    bottom: 15%;
    z-index: 20;
  }
  
  .arrow.program__arrow {
    bottom: 0px;
  }
  
  .steps__box {
    width: 22%;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .steps__box.center {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  
  .promo__date {
    font-family: MullerBold, sans-serif;
    font-size: 30px;
    line-height: 36px;
  }
  
  .promo {
    position: relative;
    min-height: 100vh;
    padding: 140px 20px 55px;
    background-image: url("../../images/promo-bg.webp");
    background-position: 0px 0px, 0px 50%;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
    color: #fff;
    
  }
  .promo::after{
    position: absolute;
    filter: brightness(60%);
    width: 100%;
    height: 100vh;
  }  
  
  .steps__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .footer {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: $color-4;
    text-align: center;
    color: #ffffff;
  }
  
  .arrow__img {
    margin-top: -18px;
    width: 100%; 
    filter: grayscale(1) brightness(1000%);
  }
  
  .how__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 70px;
    margin-left: 21%;
    padding-right: 21%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: $color-2;
  }
  
  .formula__sign {
    position: absolute;
    right: -37px;
  }
  
  .review__name {
    margin-bottom: 10px;
    font-family: MullerBold, sans-serif;
    font-weight: 700;
  }
  
  .reason__box {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 80px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .reason__img {
    position: relative;
    z-index: 2;
    width: 36%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  
  .reason__img.right {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  
  .reason__list {
    position: relative;
    z-index: 2;
   /* width: 71%;*/
  }
  
  .reason__list-item {
    margin-bottom: 20px;
    padding-left: 30px;
    background-image: url("../../images/bullit.webp");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: no-repeat;
  }
  
  .reason__bg {
    position: absolute;
    top: 25px;
    width: 41%;
    height: 100%;
    background-color: $color-1;
  }
  
  .reason__bg.right {
    right: 25px;
  }
  
  .reason__bg.left {
    left: 25px;
  }
  
  .cash-img {
    position: absolute;
    left: -23.5%;
    bottom: 11.5%;
    z-index: 10;
    max-width: 595px;
  }
  
  .coins-img {
    position: absolute;
    left: 17%;
    bottom: 11%;
    z-index: 5;
    max-width: 271px;
  }
  
  .reason__offer-img {
    position: relative;
    display: block;
    margin-left: auto;
  }
  
  .reason__offer {
    position: relative;
    z-index: 2;
    display: block;
    width: 50%;
    margin-top: -40px;
    margin-left: auto;
  }
  
  .format {
    padding: 80px 20px 5px;
    text-align: center;
  }
  
  .format__box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 75px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  
  .format__title {
    position: absolute;
    left: -60px;
    top: -30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .format__number {
    width: 163px;
    margin-right: 15px;
    font-family: MullerHeavy, sans-serif;
    color: #41bd0b;
    font-size: 118px;
    line-height: 1em;
    font-weight: 900;
    text-shadow: 2px 2px 2px #b3a991;
  }
  
  .format__wrapper {
    display: block;
    width: 950px;
    max-width: 100%;
    margin: 90px auto 70px;
  }
  
  .format__description {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 83%;
    min-height: 115px;
    padding: 30px 15px 20px 145px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: $color-2;
    text-align: left;
  }
  
  .format__subtitle {
    position: relative;
    z-index: 10;
    margin-bottom: 15px;
    font-size: 40px;
    line-height: 1em;
  }
  
  .program {
    position: relative;
    padding-top: 70px;
  }
  
  .format__img {
    width: 12%;
    text-align: center;
  }
  .format__img img{
    filter: sepia(100) saturate(50) hue-rotate(346deg);
  }
  .format__number{
    filter: sepia(100) saturate(60) hue-rotate(346deg);
  }
  .program__offer {
    position: relative;
    z-index: 2;
    width: 63%;
    margin-top: 70px;
    margin-bottom: 120px;
    margin-left: auto;
    padding-bottom: 40px;
    clear: both;
  }
  
  .formula__title {
    display: block;
    width: 1200px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .program__offer-img {
    position: absolute;
    top: -237px;
    right: 0px;
    display: block;
    max-width: 190px;
    margin-left: auto;
  }
  
  .program__box {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1200px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .program__box.second {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  
  .program__main {
    width: 42%;
  }
  
  .program__main.second {
    width: 40%;
  }
  
  .program__dop {
    position: relative;
    top: 20px;
    right: -31px;
    width: 38%;
    padding-bottom: 10px;
    background-color: $color-1;
  }
  
  .program__dop.second {
    right: 31px;
  }
  
  .main__title {
    position: relative;
    width: 91%;
    margin-bottom: 20px;
    margin-left: -30px;
    padding: 30px 15px 20px 30px;
    background-color: $color-2;
    font-family: MullerBold, sans-serif;
    font-size: 28px;
    line-height: 30px;
    font-weight: 600;
    text-align: left;
  }
  
  .main__title.second {
    width: 100%;
    margin-left: 0px;
  }
  
  .main__subtitle {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    font-family: MullerBold, sans-serif;
    font-weight: 700;
  }
  
  .list-item {
    margin-bottom: 20px;
    padding-left: 30px;
    background-image: url("../../images/bullit.webp");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    
  }
  
  .main__list {
    position: relative;
  }
  
  .dop__img {
    position: relative;
    left: -30px;
    top: -30px;
    margin-bottom: 100px;
  }
  
  .dop__img.second {
    left: 30px;
  }
  
  .sticky__wrapper {
    position: absolute;
    left: 0px;
    top: -34px;
    right: 0px;
    z-index: 10;
    width: 156px;
    height: 99%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 70px;
    padding-bottom: 200px;
  }
  
  .sticky__line {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    display: block;
    width: 3px;
    height: 99%;
    margin-right: auto;
    margin-left: auto;
    background-color: #2ca905;
  }
  
  .sticky__point {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    display: block;
    width: 17px;
    height: 17px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 50%;
    background-color: #2ca905;
  }
  
  .dop__list {
    position: relative;
    left: -30px;
    padding-left: 30px;
  }
  
  .dop__list.second {
    left: 30px;
    width: 92%;
  }
  
  .dop__subtitle {
    position: relative;
    left: -30px;
    display: inline-block;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    font-family: MullerBold, sans-serif;
    font-weight: 700;
  }
  
  .dop__subtitle.second {
    left: 30px;
  }
  
  .number__title {
    position: absolute;
    bottom: -20px;
    z-index: 10;
    font-size: 40px;
    line-height: 1em;
  }
  
  .number__count {
    position: absolute;
    left: 0px;
    bottom: 0px;
    font-family: MullerHeavy, sans-serif;
    color: #def3af;
    font-size: 174px;
    line-height: 88px;
    font-weight: 900;
  }
  
  .main__number {
    position: absolute;
    top: -119px;
    min-height: 120px;
  }
  
  .number__value {
    position: relative;
    margin-top: 50%;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0%, -50%);
    letter-spacing: 3px;
  }
  
  .main__box {
    position: relative;
    margin-right: -20px;
  }
  
  .program__wrapper {
    position: relative;
  }
  
  .program__container {
    overflow: hidden;
    padding: 190px 20px 80px;
    background-image: url("../../images/delimiter.webp");
    background-position: 50% 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .program__container.first {
    margin-top: -110px;
    padding-top: 120px;
  }
  
  .tarif {
    overflow: hidden;
    padding-top: 80px;
    text-align: center;
  }
  
  .tarif__bg {
    position: relative;
    margin-top: 55px;
    padding-top: 55px;
    padding-bottom: 90px;
    background-color: $color-5;
    /*background-image: url("../../images/delimiter.webp");*/
    background-position: 50% 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .tarif__subtitle {
    position: relative;
    z-index: 10;
   /*margin-top: -70px;*/
    margin-bottom: 35px;
    font-size: 36px;
    line-height: 1em;
    color: #ffffff;
  }
  
  .tarif__box {
    position: relative;
    width: 32%;
    box-shadow: 0 3px 9px 1px rgba(114, 70, 75, .6);
    font-family: MullerBold, sans-serif;
    cursor: pointer;
  }
  
  .tarif__title {
    padding: 30px 25px;
    color: #000000;
    font-size: 48px;
    line-height: 1em;
    text-align: center;
    border-bottom:solid 2px #000000;

  }
  .tarif__title.standart {
    background-color: #ffffff;
  }
  
  .tarif__title.pro {
    background-color: #ffffff;
  }
  
  .tarif__title.exclusive {
    background-color: #ffffff;
  }
  
  .tarif__oplata {
    display: block;
    padding: 30px 25px;
    background-color: #ffffff;
    color: #000;
    /*font-size: 28px;*/
    line-height: 1em;
    text-align: center;
    text-decoration: none;
    border: none;
    display: inline-block;
    width: 100%;
  }
  
  .tarif__description {
    padding: 20px 15px 15px 15px;
  }
  
  .tarif__description.standart {
    background-color: #ffffff;
  }
  
  .tarif__description.exclusive {
    position: relative;
    background-color: #ffffff;
  }
  
  .tarif__list {
    margin-bottom: 50px;
    font-family: MullerRegular, sans-serif;
    color: #000;
   
    text-align: left;
  }
  
  .tarif__list-item {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #000;
    min-height: 190px;
  }
  
  .tarif__list-item.inactive {
    color: #9fa298;
    text-decoration: line-through;
  }
  
  .tarif__value {
    color: #ff465d;
    font-size: 24px;
  }
  
  .tarif__people-left {
    position: absolute;
    left: 50%;
    top: 20px;
    width: 100%;
    -webkit-transform: translate(-50%, 0px);
    -ms-transform: translate(-50%, 0px);
    transform: translate(-50%, 0px);
    color: #ff465d;
    font-size: 18px;
  }
  
  .tarif__cost {
    font-size: 18px;
    line-height: 24px;
  }
  
  .tarif__wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 1200px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .goals {
    overflow: hidden;
    padding: 80px 20px 30px;
    background-image: url("../../images/delimiter.webp");
    background-position: 50% 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .goals__description {
    width: 42%;
    margin-left: -30px;
    background-color: $color-2;
  }
  
  .goals__img-wrapper {
    width: 37%;
    padding-bottom: 10px;
  }
  
  .goals__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 80px;
    padding-top: 0px;
    padding-bottom: 60px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .goals__img-box {
    position: relative;
    top: -30px;
    right: -31px;
    margin-bottom: 150px;
    background-color: $color-1;
  }
  
  .goals__img-box.last {
    margin-bottom: 0px;
  }
  
  .goals__list {
    position: relative;
    top: -40px;
    margin-right: -115px;
    padding-left: 30px;
  }
  
  .goals__img {
    position: relative;
    top: 30px;
    right: 30px;
  }
  
  .goals__list-item {
    min-height: 30px;
    margin-bottom: 15px;
    padding-top: 2px;
    padding-left: 43px;
    background-image: url("../../images/checked.webp");
    background-position: 0px 50%;
    background-size: 30px;
    background-repeat: no-repeat;
  }
  
  .dop__list-item {
    margin-bottom: 20px;
  }
  
  .review__foto-box {
    width: 40%;
  }
  
  .review__foto {
    margin-bottom: 20px;
  }
  
 /* .slider {
    padding: 50px 20px;
    background-color: $color-2;
    text-align: center;
  }
  
  .slide {
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    padding: 20px;
    background-color: #fff;
    box-shadow: -1px 1px 4px 3px #e7e0d0;
    font-size: 18px;
    line-height: 20px;
    text-align: left;
  }
  */
  .slide__name {
    margin-left: 20px;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    font-family: MullerBold, sans-serif;
    color: #353631;
    font-size: 24px;
    line-height: 26px;
    text-decoration: none;
  }
  
  .slide__name:hover {
    color: #f42034;
  }
  
  .slide__title {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .slide__quote {
    padding-right: 0px;
    border-left: 5px solid #f42034;
  }
  
  .slider__wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    min-height: 450px;
    margin-right: auto;
    margin-bottom: 35px;
    margin-left: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .slider__arrow {
    position: absolute;
    top: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 10%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #f42034;
    font-size: 40px;
    line-height: 1em;
    text-decoration: none;
  }
  
  .slider__arrow.next {
    right: 0px;
  }
  
  .slider__arrow.prev {
    left: 0px;
  }
  
  .slider__menu {
    display: none;
  }
  
  .coach {
    /*overflow: hidden;*/
    padding: 80px 0px 80px 0px;
    /*background-image: url("../../images/delimiter.webp");
    background-position: 50% 100%;
    background-size: contain;
    background-repeat: no-repeat;*/
  }
  
  .coach__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 60px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  
  .coach__description {
    width: 50%;
    margin-right: -30px;
    padding-right: 30px;
    padding-bottom: 10px;
    background-color: $color-2;
  }
  
  .coach__list {
    margin-left: -16px;
  }
  
  .coach__list-item {
    margin-bottom: 20px;
    padding-left: 48px;
    background-image: url("../../images/list-arrow.webp");
    background-position: 0px 5px;
    background-repeat: no-repeat;
  }
  
  .gallery {
    padding: 70px 20px 60px;
    background-color: $color-5 !important;
  }
  
  .guarantee {
    overflow: hidden;
    padding: 70px 20px 80px;
  }
  
  .guarantee__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .guarantee__box {
    width: 70%;
    margin-top: 30px;
    margin-right: -30px;
    padding-right: 30px;
    padding-bottom: 30px;
    background-color: $color-2;
  }
  
  .footer__link {
    display: inline-block;
    margin-bottom: 15px;
    color: #ffffff;
    text-decoration: underline;
  }
  
  .footer__link:hover {
    text-decoration: none;
    color: #ffffff;
  }
  
  .footer__link.first {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 2px solid #ffffff;
  }
  
  .contacts {
    padding: 60px 20px 20px;
  }
  
  .quote {
    padding: 120px 20px 150px;
    background-image: url("../../images/quote-bg.webp");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .quote__text {
    width: 45%;
    margin-bottom: 100px;
  }
  
  .contacts__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  
  .contacts__box {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  
  .contacts__social {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  
  .contacts__fb {
    width: 21px;
    margin-right: 15px;
  }
  
  .contacts__vk {
    width: 42px;
    margin-right: 15px;
  }
  
  .contacts__youtube {
    width: 34px;
    margin-right: 15px;
  }
  
  .contacts__tg {
    width: 40px;
    margin-right: 25px;
    filter: sepia(100) saturate(60) hue-rotate(346deg);
  }
  
  .contacts__insta {
    width: 40px;
    filter: sepia(100) saturate(60) hue-rotate(346deg);
  }
  
  .contacts__logo {
    margin-bottom: 25px;
    width: 65px;
  }
  
  .gallery__img {
    position: relative;
    width: 25%;
  }
  
  .gallery__wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .how__video {
    width: 59%;
    margin-left: -25.7%;
  }
  
  .how__content {
    width: 70%;
   /* margin-left: -20%;*/

  }
  
  .sticky__img {
    position: relative;
    top: 150px;
    z-index: 10;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  
  .lbox {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10000;
    display: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0;
  }
  
  .lbox__bg {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, .8);
    cursor: pointer;
  }
  
  .lbox__close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-size: 45px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .lbox__container {
    position: relative;
    display: block;
    width: 400px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 50px 35px 35px;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .reson__bg {
    position: absolute;
    right: 0px;
    bottom: 108px;
    width: 60%;
    height: 150px;
    background-color: $color-2;
  }
  
  .program__title-bg {
    position: absolute;
    top: 140px;
    right: 0px;
    width: 63%;
    height: 110px;
    background-color: $color-1;
  }
  
  .how__list-item {
    min-height: 26px;
    margin-bottom: 10px;
    padding-left: 30px;
    background-image: url("../../images/bullit.webp");
    background-position: 0px 3px;
    background-repeat: no-repeat;
  }
  
  .coach__img {
    width: 45%;
  }
  
  .promo__span {
    display: block;
  }
  
  html.w-mod-js *[data-ix="header-move"] {
    opacity: 0;
    -webkit-transform: translate(0px, 50px);
    -ms-transform: translate(0px, 50px);
    transform: translate(0px, 50px);
  }
  
  @media (max-width: 991px) {
    body {
      font-size: 20px;
      line-height: 22px;
    }
    h2 {
      font-size: 32px;
      line-height: 36px;
    }
    
    .navbar {
      padding-top: 15px;
      padding-right: 10px;
      padding-left: 20px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .nav__logo {
      margin-top: -10px;
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
    }
    .nav__menu {
      top: 0px;
      right: 0px;
      padding: 15px 10px 15px 15px;
      background-color: $color-2;
    }
    .nav__link {
      padding: 5px;
      font-family: MullerRegular, sans-serif;
      font-size: 20px;
    }
    .btn {
      min-width: auto;
      font-size: 20px;
    }
    .btn.nav__btn {
      margin-bottom: 0px;
      -webkit-box-ordinal-group: 1;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
    }
    .nav__menu-btn {
      padding: 5px;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
      font-size: 32px;
    }
    .nav__menu-btn.w--open {
      color: #48453d;
    }
    .review {
      padding-top: 50px;
      padding-bottom: 10px;
    }
    .review.bottom {
      padding-bottom: 70px;
    }
    .review__content {
      width: 90%;
    }
    .formula__box {
      position: static;
      width: 47%;
      height: 125px;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 18px;
      line-height: 20px;
    }
    .formula__box.left {
      margin-bottom: 60px;
    }
    .review__box {
      margin-bottom: 50px;
    }
    .section__subtitle {
      top: -15px;
      margin-bottom: 15px;
      font-size: 32px;
    }
    .formula__bg {
      margin-right: -20px;
      margin-left: -20px;
    }
    .review__video {
      left: -50px;
    }
    .how {
      padding-right: 20px;
      padding-left: 20px;
    }
    .steps {
      padding-right: 20px;
      padding-left: 20px;
    }
    .formula {
      padding-right: 20px;
      padding-left: 20px;
    }
    .formula__wrapper {
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .arrow.reason__arrow {
      bottom: 16%;
    }
    .steps__box {
      margin-bottom: 30px;
    }
    .promo {
      background-image: -webkit-linear-gradient(270deg, null, null), url("../../images/promo-bg.webp");
      background-image: linear-gradient(180deg, null, null), url("../../images/promo-bg.webp");
      background-position: 0px 0px, 50% 50%;
    }
    .formula__sign {
      left: 50%;
      right: 0%;
      width: 32px;
      -webkit-transform: translate(-50%, 0px);
      -ms-transform: translate(-50%, 0px);
      transform: translate(-50%, 0px);
    }
    .formula__sign.center {
      left: 76%;
      top: 50%;
      -webkit-transform: translate(0px, -50%) translate(-76%, 0px);
      -ms-transform: translate(0px, -50%) translate(-76%, 0px);
      transform: translate(0px, -50%) translate(-76%, 0px);
    }
    .formula__sign.up {
      left: 50%;
      right: 0%;
      -webkit-transform: translate(-50%, 0px);
      -ms-transform: translate(-50%, 0px);
      transform: translate(-50%, 0px);
    }
    .reason__img {
      width: 32%;
    }
    .reason__list {
      width: 65%;
    }
    .reason__list-item {
      padding-left: 27px;
      background-size: 17px;
    }
    
    .cash-img {
      left: -26%;
      bottom: 10%;
      width: 55vw;
    }
    .coins-img {
      left: 22%;
      bottom: 10%;
      width: 24vw;
    }
    .reason__offer-img {
      width: 18vw;
    }
    .format__box {
      margin-bottom: 60px;
    }
    .format__title {
      left: -13px;
      top: -39px;
    }
    .format__number {
      width: 126px;
      margin-right: 12px;
      font-size: 90px;
    }
    .format__description {
      min-height: 95px;
      padding-top: 20px;
      padding-bottom: 15px;
      padding-left: 152px;
    }
    .format__subtitle {
      margin-bottom: 10px;
      font-size: 32px;
    }
    .reason__content {
      font-size: 18px;
    }
    .program {
      padding-top: 79px;
    }
    .program__offer {
      width: 64%;
      margin-right: 0px;
      margin-bottom: 100px;
      padding-left: 2%;
      font-size: 18px;
    }
    .program__offer-img {
      top: -200px;
      width: 160px;
    }
    .program__dop {
      right: -20px;
      width: 39%;
    }
    .program__dop.second {
      right: 20px;
      width: 40%;
    }
    .main__title {
      margin-left: -20px;
      padding-top: 20px;
      padding-bottom: 15px;
      padding-left: 20px;
      font-size: 1em;
      line-height: 1.2em;
    }
    .list-item {
      padding-left: 27px;
      background-size: 17px;
    }
    .dop__img {
      left: -20px;
      top: -20px;
      margin-bottom: 20px;
    }
    .dop__img.second {
      left: 20px;
    }
    .sticky__wrapper {
      top: -25px;
      padding-top: 98px;
    }
    .dop__list {
      left: -20px;
      padding-left: 25px;
    }
    .dop__list.second {
      left: 20px;
    }
    .dop__subtitle {
      left: -20px;
    }
    .dop__subtitle.second {
      left: 20px;
    }
    .number__title {
      bottom: -15px;
      font-size: 32px;
    }
    .number__count {
      font-size: 130px;
      line-height: 68px;
    }
    .main__number {
      top: -91px;
      min-height: 93px;
    }
    .program__container {
      padding: 150px 20px 40px;
    }
    .tarif__bg {
      margin-top: 70px;
      padding-bottom: 50px;
    }
    .tarif__subtitle {
     /* margin-top: -85px;*/
      margin-bottom: 30px;
      font-size: 32px;
      color: #ffffff;
    }
    .tarif__box {
      position: static;
      width: 45%;
      margin-bottom: 35px;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 18px;
      line-height: 20px;
    }
    .tarif__title {
      padding: 25px 15px;
      font-size: 36px;
    }
    .tarif__oplata {
      padding: 25px 20px;
      font-size: 22px;
    }
    .tarif__description {
      padding: 15px;
    }
    .tarif__description.exclusive {
      padding-top: 0px;
    }
    .tarif__list {
      margin-bottom: 35px;
    }
    .tarif__wrapper {
      -webkit-justify-content: space-around;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .goals {
      padding-top: 60px;
    }
    .goals__description {
      width: 48%;
      margin-left: -20px;
    }
    .goals__img-wrapper {
      margin-top: 20px;
      margin-right: -20px;
    }
    .goals__wrapper {
      margin-top: 70px;
      padding-bottom: 40px;
    }
    .goals__img-box {
      top: -20px;
      right: 0px;
    }
    .goals__list {
      top: -30px;
      padding-left: 20px;
    }
    .goals__img {
      top: 20px;
      right: 20px;
    }
    .slider__wrapper {
      width: 100%;
      min-height: auto;
    }
    .coach__wrapper {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .coach__description {
      width: 54%;
    }
    .gallery {
      padding-top: 50px;
      padding-bottom: 60px;
    }
    .guarantee__img {
      max-width: 40%;
    }
    .guarantee__wrapper {
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .guarantee__box {
      width: 65%;
    }
    .quote {
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .quote__text {
      width: 50%;
      margin-bottom: 50px;
    }
    .sticky__img {
      max-width: 100px;
    }
    .reson__bg {
      bottom: 57px;
    }
    .program__title-bg {
      top: 150px;
      height: 110px;
    }
    .how__list-item {
      background-position: 0px 0px;
    }
  }
  
  @media (max-width: 767px) {
    body {
      font-size: 18px;
    }
    h1 {
      font-size: 80px;
    }
    h2 {
      font-size: 26px;
      line-height: 28px;
    }
    
    .navbar {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .nav__logo {
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
    }
    .btn {
      padding-right: 25px;
      padding-left: 25px;
      font-size: 18px;
      width: 100%;
    }
    .btn.reason__btn {
      left: 50%;
      top: -20px;
      -webkit-transform: translate(-50%, 0px);
      -ms-transform: translate(-50%, 0px);
      transform: translate(-50%, 0px);
    }
    .btn.how__btn {
      position: relative;
      bottom: -20px;
      float: none;
    }
    .nav__menu-btn {
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
    }
    .review {
      padding-top: 45px;
      padding-bottom: 10px;
      background-color: $color-5;
    }
    .review.bottom {
      padding-top: 35px;
      padding-bottom: 60px;
    }
    .review__content {
      padding-bottom: 30px;
    }
    .review__content.small {
      font-size: 18px;
      line-height: 22px;
    }
    .formula__box {
      font-size: 16px;
    }
    .formula__box.left {
      margin-bottom: 50px;
    }
    .reason {
      padding-bottom: 0px;
    }
    .review__box {
      display: block;
      width: 100%;
      max-width: 500px;
      margin-right: auto;
      margin-bottom: 30px;
      margin-left: auto;
    }
    .review__wrapper {
      display: block;
    }
    .promo__sutitle {
      font-size: 22px;
      line-height: 24px;
    }
    .section__subtitle {
      top: -12px;
      margin-bottom: 0px;
      font-size: 26px;
    }
    .steps__img {
      max-height: 70px;
      
    }
    .formula__bg {
      margin-top: 30px;
    }
    .how {
      padding-top: 60px;
      padding-bottom: 30px;
      position: relative;
      overflow: hidden;
      background-image: url(../../images/delimiter.webp);
      background-position: 50% 100%;
      background-size: contain;
      background-repeat: no-repeat;
    }
    .steps {
      padding-top: 30px;
      padding-bottom: 50px;
    }
    .formula {
      padding-top: 30px;
    }
    .formula__wrapper {
      margin-top: 15px;
    }
    .arrow {
      bottom: -35px;
      height: 90px;
    }
    .arrow.reason__arrow {
      position: relative;
      margin-top: -20px;
    }
    .arrow.program__arrow {
      left: 0%;
      bottom: -23px;
      display: none;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
    .steps__box {
      width: 45%;
    }
    .promo__date {
      margin-bottom: 60px;
      font-size: 26px;
      line-height: 30px;
    }
    .promo {
      padding-top: 150px;
      padding-bottom: 30px;
      background-image: -webkit-linear-gradient(270deg, transparent, transparent), url("../../images/promo-bg.webp");
      background-image: linear-gradient(180deg, transparent, transparent), url("../../images/promo-bg.webp");
      background-position: 0px 0px, 55% 50%;
    }
    .footer {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .how__wrapper {
      display: block;
      margin-left: 0%;
      padding-right: 20px;
      padding-left: 20px;
      text-align: center;
    }
    .how__list {
      margin-bottom: 0px;
      text-align: left;
    }
    .reason__box {
      display: block;
      margin-bottom: 75px;
    }
    .reason__img {
      display: block;
      width: auto;
      margin-right: auto;
      margin-bottom: 25px;
      margin-left: auto;
    }
    .reason__list {
      width: 100%;
      padding-right: 13px;
      padding-left: 13px;
    }
    .reason__list-item {
      margin-bottom: 10px;
      padding-left: 23px;
      background-size: 15px;
    }
    .reason__bg {
      width: 100%;
      height: 100%;
    }
    .reason__bg.right {
      right: 0px;
    }
    .reason__bg.left {
      left: 0px;
    }
    .cash-img {
      bottom: 11%;
      width: 55vw;
    }
    .coins-img {
      left: 23%;
      bottom: 11%;
      width: 29vw;
    }
    .reason__offer-img {
      width: 150px;
      margin-bottom: 0px;
    }
    .reason__offer {
      width: 100%;
    }
    .format {
      padding-top: 50px;
    }
    .format__box {
      margin-bottom: 50px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .format__title {
      top: -28px;
    }
    .format__number {
      width: 92px;
      font-size: 66px;
    }
    .format__wrapper {
      margin-bottom: 0px;
    }
    .format__description {
      width: 81%;
      padding-left: 110px;
    }
    .format__subtitle {
      margin-bottom: 8px;
      font-size: 24px;
    }
    .reason__content {
      display: block;
      width: 60%;
      margin-top: 0px;
      margin-right: -20px;
      margin-left: auto;
      padding-right: 15px;
      padding-bottom: 30px;
      padding-left: 15px;
      background-color: $color-2;
    }
    .format__img {
      width: 12%;
      min-width: 70px;
    }
    .program__offer {
      width: 74%;
      margin-right: 0px;
      padding-right: 15px;
      padding-bottom: 20px;
      padding-left: 15px;
      background-color: $color-1;
    }
    .program__offer-img {
      top: -163px;
      width: 130px;
    }
    .program__box {
      display: block;
      padding-left: 20px;
    }
    .program__main {
      width: 100%;
      margin-bottom: 50px;
    }
    .program__main.second {
      width: 100%;
    }
    .program__dop {
      right: 20px;
      width: 100%;
      padding-left: 20px;
    }
    .program__dop.second {
      width: 100%;
    }
    .main__title {
      width: 100%;
      padding-left: 20px;
    }
    .main__title.second {
      margin-left: -20px;
    }
    .list-item {
      margin-bottom: 10px;
      padding-left: 23px;
      background-size: 15px;
    }
    .dop__img {
      width: 100%;
      max-width: 350px;
      margin-bottom: 10px;
    }
    .dop__img.second {
      left: -20px;
    }
    .sticky__wrapper {
      left: 14px;
      top: 0px;
      display: block;
      width: 15px;
      height: 99.6%;
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }
    .sticky__line {
      top: 0px;
      height: 99.5%;
    }
    .sticky__point {
      left: -1px;
      top: 0px;
    }
    .dop__list {
      left: 0px;
      padding-left: 20px;
    }
    .dop__list.second {
      left: 0px;
      width: 100%;
    }
    .dop__subtitle {
      left: 0px;
      display: block;
      width: 230px;
    }
    .dop__subtitle.second {
      left: 0px;
    }
    .number__title {
      bottom: -10px;
      font-size: 24px;
    }
    .number__count {
      font-size: 90px;
      line-height: 50px;
    }
    .main__number {
      top: -62px;
      min-height: 65px;
    }
    .number__value {
      letter-spacing: 0px;
    }
    .main__box {
      margin-right: 0px;
    }
    .program__container {
      padding-top: 110px;
      padding-bottom: 60px;
    }
    .tarif {
      padding-top: 50px;
    }
    .tarif__bg {
      margin-top: 30px;
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 45px;
      padding-bottom: 25px;
    }
    .tarif__subtitle {
      margin-top: 0px;
      font-size: 26px;
      color: #ffffff;
    }
    .tarif__box {
      display: block;
      width: 75%;
      margin-right: auto;
      margin-left: auto;
      font-size: 16px;
      line-height: 18px;
      background-color: $color-5;
    }
    .tarif__title {
      font-size: 32px;
    }
    .tarif__oplata {
      font-size: 20px;
    }
    .tarif__description {
      padding-left: 20px;
    }
    .tarif__wrapper {
      display: block;
    }
    .goals {
      padding-top: 50px;
      padding-bottom: 0px;
    }
    .goals__description {
      width: 100%;
      margin-bottom: 70px;
    }
    .goals__img-wrapper {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      margin-right: 0px;
      margin-left: -20px;
      padding-left: 20px;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
    .goals__wrapper {
      display: block;
      padding-bottom: 50px;
    }
    .goals__img-box {
      top: -20px;
      right: -20px;
      width: 45%;
      margin-bottom: 75px;
    }
    .goals__list {
      margin-right: 20px;
      padding-right: 25px;
    }
    .goals__img {
      top: 20px;
      right: 20px;
    }
    .goals__list-item {
      min-height: 24px;
      margin-bottom: 5px;
      padding-left: 35px;
      background-size: 24px;
      line-height: 20px;
    }
    .dop__list-item {
      margin-bottom: 10px;
    }
    .review__foto-box {
      display: block;
      width: 75%;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
    }
    .slide {
      width: 100%;
      margin-left: 0%;
    }
    .slide__name {
      font-size: 22px;
      line-height: 24px;
    }
    .slider__arrow {
      top: auto;
      bottom: -83px;
      width: 50px;
      height: 50px;
    }
    .coach {
      padding-top: 45px;
      padding-bottom: 60px;
      text-align: center;
    }
    .coach__wrapper {
      display: block;
      margin-top: 0px;
    }
    .coach__description {
      width: 100%;
      margin-right: 0px;
      padding-right: 15px;
    }
    .coach__list {
      margin-left: -10px;
      text-align: left;
    }
    .coach__list-item {
      margin-bottom: 10px;
      padding-left: 40px;
      background-size: 33px;
    }
    .gallery {
      padding-bottom: 40px;
    }
    .guarantee {
      padding-top: 45px;
      padding-bottom: 50px;
    }
    .guarantee__img {
      display: block;
      width: 100%;
      max-width: 200px;
      margin-right: auto;
      margin-bottom: 35px;
      margin-left: auto;
    }
    .guarantee__wrapper {
      display: block;
      margin-top: 0px;
    }
    .guarantee__box {
      width: 100%;
      margin-right: 0px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .contacts {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .quote__text {
      width: 55%;
    }
    .contacts__wrapper {
      display: block;
      text-align: center;
    }
    .contacts__box {
      display: block;
    }
    .contacts__box.top {
      margin-bottom: 25px;
    }
    .contacts__social {
      margin-bottom: 15px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .contacts__fb {
      width: 18px;
    }
    .contacts__vk {
      width: 37px;
    }
    .contacts__youtube {
      width: 29px;
    }
    .contacts__tg {
      width: 28px;
    }
    .contacts__insta {
      width: 35px;
    }
    .contacts__logo {
      width: 65px;
      margin-bottom: 15px;
    }
    .gallery__img {
      width: 33.33333333%;
    }
    .gallery__img.logo {
      display: none;
    }
    .how__video {
      position: relative;
      top: -30px;
      display: block;
      width: 100%;
      max-width: 500px;
      margin-right: auto;
      margin-bottom: 10px;
      margin-left: auto;
    }
    .how__content {
      width: 100%;
      margin-left: 0%;
    }
    .sticky__img {
      display: none;
    }
    .reson__bg {
      display: none;
    }
    .program__title-bg {
      display: none;
    }
    .how__list-item {
      min-height: 20px;
      margin-bottom: 5px;
    }
    .coach__img {
      display: block;
      width: 100%;
      max-width: 300px;
      margin-right: auto;
      margin-bottom: 35px;
      margin-left: auto;
    }
    .promo__span {
      display: inline;
    }
  }
  
  @media (max-width: 479px) {
    body {
      font-size: 16px;
      line-height: 18px;
    }
    h1 {
      font-size: 54px;
    }
    .navbar {
      padding: 10px 0px 10px 15px;
    }
    .nav__logo {
      width: 108px;
    }
    .nav__menu {
      padding-left: 10px;
    }
    .btn.nav__btn {
      font-size: 18px;
      margin-bottom: 10px;
    }
    ul{
      margin-top: 0px;
    }
    .review.bottom {
      padding-bottom: 50px;
    }
    .review__content {
      width: 100%;
      padding-right: 20px;
      padding-bottom: 30px;
      padding-left: 20px;
    }
    .review__content.small {
      font-size: 16px;
      line-height: 18px;
    }
    .formula__box {
      position: relative;
      width: 100%;
      height: 118px;
      font-size: 18px;
    }
    .formula__box.left {
      margin-bottom: 50px;
    }
    .formula__box.center {
      margin-bottom: 50px;
    }
    .section__subtitle_2{top: -30px;}
    
    .promo__sutitle {
      font-size: 18px;
      line-height: 20px;
    }
    .formula__bg {
      padding-top: 35px;
    }
    .review__video {
      left: 0px;
    }
    .arrow.reason__arrow {
      position: relative;
      margin-top: -40px;
      margin-bottom: 50px;
    }
    .steps__box {
      display: block;
      width: 90%;
      margin-right: auto;
      margin-left: auto;
    }
    .promo__date {
      font-size: 24px;
      line-height: 28px;
    }
    .promo {
      padding-right: 15px;
      padding-left: 10px;
      text-align: center;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(../../images/promo-bg-m.webp);
    }
    .how__wrapper {
      width: 100%;
      margin-right: 0px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .formula__sign {
      top: 106%;
    }
    .formula__sign.center {
      left: 50%;
      top: 106%;
      -webkit-transform: translate(-50%, 0px);
      -ms-transform: translate(-50%, 0px);
      transform: translate(-50%, 0px);
    }
    .formula__sign.up {
      top: 113%;
      -webkit-transform: translate(-50%, 0px) rotate(90deg);
      -ms-transform: translate(-50%, 0px) rotate(90deg);
      transform: translate(-50%, 0px) rotate(90deg);
    }
    .cash-img {
      bottom: 195px;
    }
    .coins-img {
      left: 27%;
      bottom: 195px;
      z-index: 8;
    }
    .reason__offer-img {
      width: 34vw;
    }
    .reason__offer {
      width: 100%;
      margin-top: -60px;
      padding-right: 0px;
      float: none;
    }
    .format {
      text-align: left;
    }
    .format__box {
      display: block;
      margin-bottom: 40px;
    }
    .format__title {
      top: -26px;
    }
    .format__number {
      width: auto;
      margin-right: 10px;
      font-size: 60px;
    }
    .format__wrapper {
      margin-top: 40px;
    }
    .format__description {
      width: 100%;
      min-height: 70px;
      margin-bottom: 0px;
      padding-top: 35px;
      padding-bottom: 20px;
      padding-left: 15px;
    }
    .format__subtitle {
      margin-bottom: 5px;
      font-size: 20px;
    }
    .reason__content {
      width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      padding-top: 30px;
      padding-right: 20px;
      padding-left: 20px;
      font-size: 16px;
    }
    .format__img {
      display: none;
      margin-right: auto;
      margin-left: auto;
    }
    .program__offer {
      width: 100%;
      margin-right: 0px;
      padding-top: 30px;
      padding-right: 10px;
      font-size: 16px;
    }
    .program__offer-img {
      margin-bottom: 20px;
    }
    .program__box {
      padding-left: 15px;
    }
    .program__main {
      margin-bottom: 30px;
    }
    .program__dop {
      right: 15px;
      padding-left: 15px;
    }
    .program__dop.second {
      right: 15px;
    }
    .main__title {
      width: 100%;
      margin-bottom: 15px;
      margin-left: -15px;
      padding-left: 15px;
    }
    .main__title.second {
      margin-left: -15px;
    }
    .dop__img {
      left: -15px;
      top: -15px;
    }
    .dop__img.second {
      left: -15px;
    }
    .sticky__wrapper {
      height: 99.3%;
    }
    .dop__subtitle {
      width: 205px;
    }
    .number__title {
      font-size: 22px;
    }
    .tarif__bg {
      padding: 35px 15px  15px  15px;
    }
    .tarif__list-item {
      min-height: auto !important;
  }
    .tarif__box {
      width: 100%;
    }
    .tarif__list {
      font-size: 16px;
      line-height: 14px;
    }
    .goals__description {
      margin-bottom: 50px;
      margin-left: 0px;
    }
    .goals__img-wrapper {
      display: block;
      margin-left: 0px;
      padding-left: 15px;
    }
    .goals__wrapper {
      margin-top: 30px;
    }
    .goals__img-box {
      right: 0px;
      display: block;
      width: 85%;
      max-width: 250px;
      margin-right: auto;
      margin-bottom: 50px;
      margin-left: auto;
    }
    .goals__list {
      top: 0px;
      margin-right: 0px;
      padding: 15px 10px 15px 15px;
    }
    .goals__list-item {
      min-height: 20px;
      padding-left: 30px;
      background-size: 20px;
    }
    .review__foto-box {
      width: 100%;
    }
    .slide__name {
      font-size: 18px;
      line-height: 20px;
    }
    .slide__quote {
      padding-left: 10px;
      border-left-style: solid;
      border-left-width: 3px;
      font-size: 16px;
      line-height: 18px;
    }
    .slider__arrow {
      width: 35px;
      height: 50px;
    }
    .guarantee {
      padding-bottom: 45px;
    }
    .guarantee__box {
      padding-top: 35px;
      padding-bottom: 25px;
    }
    .footer__link {
      margin-right: 15%;
      margin-left: 15%;
    }
    .footer__link.first {
      margin-right: 15%;
      margin-bottom: 0px;
      padding-right: 10px;
      padding-bottom: 5px;
      padding-left: 10px;
      border-right-style: none;
    }
    .quote {
      padding-top: 30px;
      padding-bottom: 40px;
      background-position: 45% 50%;
    }
    .quote__text {
      width: 60%;
    }
    .gallery__img {
      width: 50%;
    }
    .gallery__img.logo {
      display: block;
    }
    .lbox__container {
      margin-right: 10px;
      margin-left: 10px;
      padding: 20px;
    }
    .m-btn{
      display: flex;
      justify-content: center;
    }
  }
  @font-face {
    font-family: 'MullerRegular';
    src: url('../fonts/MullerRegular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Mullerhairline';
    src: url('../fonts/MullerHairline.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'MullerBold';
    src: url('../fonts/MullerBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'MullerHeavy';
    src: url('../fonts/MullerHeavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
  }

  .arrow__line {
    height: 100%;
    animation: pulse 5s infinite;
    animation-name: pulse_1;
  }
  .arrow__left,.arrow__right{
    animation: pulse 5s infinite;
    animation-name: pulse;
    
  }
  @keyframes pulse_1 {
    0% {
      height: 100%;
    }
    50% {
      height: 70%
    }
    100% {
      height: 100%
    }
    
  }
  
  @keyframes pulse {
    0% {
      bottom: 0%;
    }
    50% {
      bottom: 30%;
    }
    100% {
      bottom: 0%;
    }
    
  }
  .bg-light {
    --bs-bg-opacity: 0;
  }
  .navbar-collapse {
    justify-content: space-around;
  }
  @media (min-width: 992px){
    .navbar-expand-lg .navbar-collapse {
      justify-content: space-around !important;
    }
  }
  .nav-item{
    padding: 0 20px;
  }
  video{
    height: auto;
    width: 100%;
    max-height: 500px;
    max-width: 300px;
  }
  .video-box{
    display: inline !important;
  }
  #carouselReview{
  .carousel-item,.carousel{
    position: relative;
    text-align: center;
    background-color: transparent;
    box-shadow: none;
  }
}
.carousel-control-next-icon,.carousel-control-prev-icon{
  filter: invert(75%);
}
.carousel-indicators{
  bottom: -50px;
}
.carousel-item{
 a,a:hover{
  color: rgba(0, 0, 0, 0);
 }
}
  #carouselGallery{
    .carousel-item,.carousel{
      position: relative;
      text-align: center;
      background-color: transparent;
      box-shadow: none;
    } 
  }
  .vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .nastavnik{
    width: 100%;
    height:auto ;
    max-width: 500px;
  }

  .fan-img{
    width: auto;
    height: 350px;
    padding:6px;
    border: 3px solid #f42034;
  }
  @media (max-width: 479px) {
    .fan-img{
      width:100%;
      height: auto;
    }
    .nav-item{
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  
  .am-wrapper{
    float:left;
    position:relative;
    overflow:hidden;
  }
  .am-wrapper img{
    position:absolute;
    outline:none;
  }
  .trener{
    width:auto;
    max-height:90%;
    @media (max-width: 479px) {
      width:100%;
    }
  }
  iframe{
    height: 100%;
    min-height: 450px;
  }

#promokod_block{
  background-color:#ffffff;
  padding:20px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
}
.promokod_block_close{
  position: absolute;
  top:10px;
  right:10px;
  cursor: pointer;
}
.gallery h2,.review h2{
  color:#ffffff;
}  
.carousel-control-next-icon, .carousel-control-prev-icon {
  filter: invert(0%);
}
.shadow{
  background-color: #ffffff;
}